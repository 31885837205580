import { EventEmitter, Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { catchError } from 'rxjs/operators'
import { Observable, throwError } from 'rxjs'

import { Structure } from '@src/models/structure.model'
import { Environment } from '@src/environments/environment'
import { Employee, EmployeeDto } from '@src/models/employee.model'

@Injectable({
  providedIn: 'root',
})
export class StructureService {
  private apiPath: string = Environment.API_PATH

  public onEmployees: EventEmitter<EmployeeDto[]> = new EventEmitter<EmployeeDto[]>()

  constructor(private http: HttpClient) {}

  getStructures(): Observable<Structure[]> {
    return this.http.get<Structure[]>(`${this.apiPath}/v1/structures`).pipe(
      catchError(() => {
        return throwError(() => 'Impossible de charger les structures')
      })
    )
  }

  getStructureByRid(structureRid: string): Observable<Structure> {
    return this.http.get<Structure>(`${this.apiPath}/v1/structures/${structureRid}`).pipe(
      catchError(() => {
        return throwError(() => 'Impossible de charger la structure')
      })
    )
  }

  updateStructureByRid(structureRid: string, structure: any): Observable<any> {
    return this.http.put<Structure>(`${this.apiPath}/v1/structures/${structureRid}`, structure).pipe(
      catchError(({ error }) => {
        return throwError(
          () =>
            'Impossible de modifier la structure : ' +
            JSON.stringify(error.detail ? error.detail : error, null).replace('"', '')
        )
      })
    )
  }

  getStructureInfo(structureRid: string): Observable<any> {
    return this.http.get<any>(`${this.apiPath}/v1/structures/${structureRid}/info`).pipe(
      catchError(() => {
        return throwError(() => 'Impossible de charger les infos de la structure')
      })
    )
  }

  getStructuresCount(): Observable<any> {
    return this.http.get<any>(`${this.apiPath}/v1/structures/count`).pipe(
      catchError(() => {
        return throwError(() => 'Impossible de charger le nombre de structures')
      })
    )
  }

  createStructure(structure: Structure): Observable<any> {
    return this.http.post<Structure>(`${this.apiPath}/v1/structures`, structure).pipe(
      catchError(({ error }) => {
        return throwError(() => 'Impossible de créer la structure : ' + JSON.stringify(error))
      })
    )
  }

  activateStructureByRid(structureRid: string, isActive: boolean): Observable<any> {
    return this.http
      .put<Structure>(`${this.apiPath}/v1/structures/${structureRid}/active`, { is_active: isActive })
      .pipe(
        catchError(({ error }) => {
          return throwError(() => 'Impossible de modifier la structure : ' + JSON.stringify(error))
        })
      )
  }

  getStructureEmployees(structureId: number): Observable<EmployeeDto[]> {
    return this.http.get<EmployeeDto[]>(`${this.apiPath}/v1/structures/${structureId}/employees`).pipe(
      catchError(() => {
        return throwError(() => 'Impossible de charger les salariés')
      })
    )
  }

  createStructureEmployee(structureId: number, employee: Employee): Observable<EmployeeDto> {
    return this.http.post<EmployeeDto>(`${this.apiPath}/v1/structures/${structureId}/employees`, employee).pipe(
      catchError((error) => {
        let errorMessage = 'Impossible de créer le salarié'

        if (error.status == 409) {
          errorMessage = "L'adresse email est déjà utilisée"
        }

        return throwError(() => errorMessage)
      })
    )
  }

  updateStructureEmployee(structureId: number, employeeId: number, employee: Employee): Observable<EmployeeDto> {
    return this.http
      .put<EmployeeDto>(`${this.apiPath}/v1/structures/${structureId}/employees/${employeeId}`, employee)
      .pipe(
        catchError((error) => {
          let errorMessage = 'Impossible de modifier le salarié'

          if (error.status == 409) {
            errorMessage = "Le numéro de cafat (ou l'adresse email) est déjà utilisé(e)"
          }

          return throwError(() => errorMessage)
        })
      )
  }

  createStructureMembers(structureId: number): Observable<void> {
    return this.http.post<void>(`${this.apiPath}/v1/structures/${structureId}/members`, null).pipe(
      catchError((error) => {
        let errorMessage = 'Impossible de se rattacher à la structure'

        if (error.status == 409) {
          errorMessage = 'Vous êtes déjà rattaché à la structure'
        }

        return throwError(() => errorMessage)
      })
    )
  }

  getStructureMembers(structureId: string): Observable<any> {
    return this.http.get<any>(`${this.apiPath}/v1/structures/${structureId}/members`).pipe(
      catchError(() => {
        return throwError(() => 'Impossible de charger les membres de la structures')
      })
    )
  }

  getTrainingOrganizationByRid(structureRid: string): Observable<Structure> {
    return this.http.get<Structure>(`${this.apiPath}/v1/training_organizations/${structureRid}`).pipe(
      catchError(() => {
        return throwError(() => "Impossible de charger l'organisme de formation")
      })
    )
  }

  updateTrainingOrganizationByRid(structureRid: string, trainingOrganisation: any): Observable<any> {
    return this.http.put<any>(`${this.apiPath}/v1/training_organizations/${structureRid}`, trainingOrganisation).pipe(
      catchError(({ error }) => {
        return throwError(
          () =>
            "Impossible de modifier l'organisme de formation: " +
            JSON.stringify(error.detail ? error.detail : error, null).replace('"', '')
        )
      })
    )
  }

  getStructuretHistory(structureRid: string): Observable<Structure> {
    return this.http.get<Structure>(`${this.apiPath}/v1/structures/${structureRid}/histories`).pipe(
      catchError(() => {
        return throwError(() => "Impossible de charger l'historique des modifications de la structure")
      })
    )
  }

  deleteStructureMembers(structureRid: string, personId: number): Observable<any> {
    return this.http.delete<any>(`${this.apiPath}/v1/structures/${structureRid}/members/${personId}`).pipe(
      catchError(() => {
        return throwError(() => 'Impossible de supprimer les personnes rattachées à la structure')
      })
    )
  }

  updateStructureMemberManager(structureRid: string, personId: number, isManager: boolean): Observable<any> {
    return this.http.put<any>(`${this.apiPath}/v1/structures/${structureRid}/members/${personId}`, { "is_manager": isManager }).pipe(
      catchError(() => {
        return throwError(() => 'Impossible de mettre à jour le responsable de la structure')
      })
    )
  }

  updateRIB(formData: FormData): Observable<any> {
    return this.http.post<void>(`${this.apiPath}/v1/files`, formData).pipe(
      catchError((error) => {
        return throwError(() => 'Impossible de télécharger le RIB')
      })
    )
  }
}
